<template>
  <div class="gray_bg">
    <div class="page-container">
      <div class="login-page">
        <div class="reset-password">
          <div class="notification-message" v-if="confirmedEmailNotification">
            <b>Thank you!</b><br>
            <span>Your e-mail has been confirmed. Now you could use all {{$texts.brand_name}} features</span>
          </div>

          <div class="notification-message" v-if="changedPasswordNotification">
            <b>Password changed successfully!</b><br>
            <span>Please login to your account to get the full {{$texts.brand_name}} Experience.</span>
          </div>

          <h1 class="login-page_title" v-if="!confirmedEmailNotification && !changedPasswordNotification">Login</h1>
          <div class="user-login-form card-section">
            <p>Please login to your account to get the full {{$texts.brand_name}} Experience.</p>
            <v-form ref="form" @submit.prevent="userLogin">
              <div class="form-row">
                <label>Email</label>
                <v-text-field
                  solo
                  type="email"
                  v-model="$v.loginData.email.$model"
                  :error-messages="userLoginErrors"
                  @change="$v.loginData.email.$touch()"
                  @blur="$v.loginData.email.$touch()"
                  autocomplete="new-login"
                ></v-text-field>
              </div>
              <div class="form-row">
                <label>Password</label>
                <v-text-field
                  solo
                  type="password"
                  v-model="$v.loginData.password.$model"
                  :error-messages="userPasswordErrors"
                  @change="$v.loginData.password.$touch()"
                  @blur="$v.loginData.password.$touch()"
                  autocomplete="new-password"
                ></v-text-field>
              </div>
              <div class="v-messages theme--light error--text" v-if="authErrors">
                <div class="v-messages__message" v-for="(error, index) in authErrors" :key="index">{{error.detail}}</div>
              </div>
              <div class="text--center">
                <v-btn color="primary" type="submit" depressed large :loading="loadingLogin">
                  <span class="user-login-btn">Login</span>
                </v-btn>
                <div class="reset-password">
                  <a href="#" @click.prevent="showResetPasswordDialog">Forgot password</a>
                </div>
                <div class="create-new-account">
                  <span>Didn’t have account yet? <nuxt-link to="/registration">Register now!</nuxt-link></span>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <ResetPassword :showResetPassword="showResetPassword" @hideResetPasswordDialog="hideResetPasswordDialog"/>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email , sameAs} from 'vuelidate/lib/validators'
import Cookies from 'js-cookie'
import ResetPassword from '@/components/ResetPassword/ResetPassword'

export default {
  mixins: [validationMixin],
  data() {
    return {
      loginData: {
        email: '',
        password: '',
      },
      loadingLogin: false,
      authErrors: null,
      showResetPassword: false
    }
  },
  validations: {
    loginData: {
      email: { required, email },
      password: { required },
    }
  },
  methods: {
    userLogin() {
      this.$v.loginData.$touch()
      if (!this.$v.loginData.$invalid) {
        this.loadingLogin = true
        this.authErrors = null
        let data = {
          'email': this.loginData.email,
          'password': this.loginData.password,
        }

        this.$axios.post('/api/user/v1/auth/sign-in', data, {
          headers: {
            'Authorization': ''
          }
        }).then(res => {
          Cookies.set('auth', res.data)
          this.loadingLogin = false
          this.$store.dispatch('setAuth')
          this.$store.dispatch('getUser')
          this.$router.push('/')
        }).catch(error => {
          this.loadingLogin = false
          this.authErrors = error.response.data.errors
        })
      }
    },
    showResetPasswordDialog() {
      this.showResetPassword = true
    },
    hideResetPasswordDialog() {
      this.showResetPassword = false
    }
  },
  computed: {
    userLoginErrors () {
      const errors = []
      if (!this.$v.loginData.email.$dirty) return errors
      !this.$v.loginData.email.email && errors.push('Must be valid e-mail')
      !this.$v.loginData.email.required && errors.push('Login is required.')
      return errors
    },
    userPasswordErrors() {
      const errors = []
      if (!this.$v.loginData.password.$dirty) return errors
      !this.$v.loginData.password.required && errors.push('Password is required.')
      return errors
    },
    confirmedEmailNotification() {
      return this.$store.state.confirmedEmailNotification
    },
    changedPasswordNotification() {
      return this.$store.state.changedPasswordNotification
    },
  },
  watch: {
    userPasswordErrors() {
      this.authErrors = []
    }
  },
  beforeDestroy() {
    this.$store.commit('SET_CONFIRMED_EMAIL_NOTIFICATION', false)
    this.$store.commit('SET_RESET_PASSWORD_NOTIFICATION', false)
  },
  components: {
    ResetPassword
  },
}
</script>
<style>
  .user-login-form {
    max-width: 512px;
    margin: 0 auto 70px auto;
  }

  .login-page {
    margin: 20px auto;
    min-height: calc(100vh - 394px);
  }

  .reset-password {
    margin: 12px 0;
  }

  .login-page_title {
    margin-bottom: 32px;
    font-size: 30px;
    text-align: center;
  }

  .user-login-btn {
    padding: 0 40px;
  }

  .notification-message {
    background: rgba(151, 211, 52, 0.1);
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 32px;
    text-align: center;
  }
</style>
